<template>
  <div class="container">
    <headBar ref="header" :title="title" left-arrow @click-left="backFun" background="#3665BD" color="#fff" />
    <div class="sticky" :style="{ 'top': stickyHeight + 'px' }">
      <div class="top">
        <div class="row-between-center">
          <div class="pub_onefull">
            <p class="row-between-center">
              <span class="f36 fw5">{{ totalInfo.activityName }}</span>
              <span class="time" v-if="menuIndex != 0" @click="showTime">{{ timeText }}<van-icon
                  name="arrow-down" /></span>
            </p>
            <p class="f22 fw4 mt8">{{ totalInfo.activityTime }}</p>
          </div>
          <div v-if="menuIndex == 0 && !isHistory && goTag == 0" class="qr-code" @click="choseTagName">
            <van-icon name="qr" /><span>分享二维码</span>
          </div>
        </div>
        <div class="num">
          <template v-if="goSTId != -1">
            <div v-for="i in Object.keys(numObj)" v-if="menuIndex == 2 || i != 'publicSea'">
              <p>{{ numObj[i] }}</p>
              <p><span>{{ totalInfo[i + 'Num'] || 0 }}</span><span>人</span></p>
            </div>
          </template>
          <template v-else>
            <div v-for="i in Object.keys(numObj2)">
              <p>{{ numObj2[i] }}</p>
              <p><span>{{ totalInfo[i + 'Num'] || 0 }}</span><span>人</span></p>
            </div>
          </template>
        </div>
        <div class="total">
          <div v-for="(i, key) in Object.keys(totalObj)">
            <p>{{ totalObj[i] }}</p>
            <p>
              <span>{{ totalInfo[i + 'Num'] || 0 }}</span><span>{{ [0, 1, 4, 5].indexOf(key) > -1 ? '张' :
                '份' }}</span>
            </p>
            <p><span>{{ totalInfo[i + 'Amount'] || 0 }}</span><span>元</span></p>
          </div>
        </div>
        <div class="tips">
          <p>数据更新于：{{ nowTime }}</p>
          <p class="mt10" style="color:#8CECFF" @click="topTipsShow = true">消费金额(仅转化)为会员绑定后30天内在所有渠道购买的销售总额<van-icon
              class="ml10" name="warning" /></p>
        </div>
      </div>
      <div class="tabs">
        <p class="tab" :class="{ act: i.key == active }" v-for="i in tabFilterList" @click="tabChange(i.key)">
          <span> {{ i.name }}</span>
        </p>
      </div>
    </div>
    <div ref="listRef">
      <div v-if="active == 0 || active == 3">
        <teamListVue :menuIndex="menuIndex" :active="active" :listHeight="listHeight" ref="teamRef"
          @teamList="teamListFun" @goTeamAndUser="goTeamAndUser" @refresh="getTotal" />
      </div>
      <div v-if="[1, 2, 4, 6, 7, 8].indexOf(active) > -1">
        <memberListVue :menuIndex="menuIndex" :active="active" :listHeight="listHeight" :minDate="minDate"
          :maxDate="maxDate" :teamList="menuIndex == 0 ? [] : teamList" ref="memberRef" @refresh="getTotal" />
      </div>
      <div v-if="active == 5">
        <buyListVue :menuIndex="menuIndex" :listHeight="listHeight" ref="buyRef" @refresh="getTotal" />
      </div>
    </div>
    <van-popup v-model="qrShow" round class="qr-box" v-if="menuIndex == 0">
      <p class="f40 fw6 ellipsis-1">{{ totalInfo.activityName }}</p>
      <p class="f32 mt28">{{ totalInfo.userName }}</p>
      <img class='qr' :src="`data:image/png;base64,${qrCode}`" alt="">
      <p class="f32" v-if="tagNameList.length>0">获客标签：{{ tagName }}</p>
      <img class="close" @click="qrShow = false"
        src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png">
    </van-popup>
    <van-popup v-model="newTipsShow" round class="new-tip-pop" v-if="menuIndex != 2">
      <p class="n-title">提示</p>
      <p v-if="tipsObj.expireNum != 0">本日 <span class="blue">{{ tipsObj.expireNum }}</span>位会员将于7天内到期</p>
      <p v-if="tipsObj.indoorNum != 0">本日<span class="blue">{{ tipsObj.indoorNum }}</span>位会员进馆</p>
      <p v-if="tipsObj.invertNum != 0">本日<span class="blue">{{ tipsObj.invertNum }}</span>位会员转化</p>
      <p v-if="menuIndex != 0 && tipsObj.publicSeaNum != 0">本日<span class="blue">{{ tipsObj.publicSeaNum }}</span>位会员进入公海
      </p>
      <div class="btns" @click="newTipsShow = false">我知道了</div>
    </van-popup>
    <van-popup v-model="topTipsShow" round class="tips-box" closeable>
      <p>商品类型</p>
      <p class="mt20">超鹿月卡（大于7天的卡）、包月私教卡、私教课包（含299元、99元）、礼品卡，企业充值除外。</p>
    </van-popup>
    <choseCalendarVue ref="choseTimeRef" :minDate="minDate" :maxDate="maxDate" @choseTime="choseTimeFun"
      @choseAll="selectAllFun" />
    <van-popup v-model="choseTagShow" round position="bottom" class="pop" safe-area-inset-bottom closeable>
      <p class="title">选择获客标签</p>
      <div class="chose-item choseTag">
        <div class="c-items">
          <span class="ellipsis-1" v-for="i in tagNameList" :class="untagSourceId == i.sourceId ? 'act' : ''" @click="choseSource(i)">{{
            i.name
          }}</span>
        </div>
      </div>
      <div class="btn-box">
        <span @click="cancel">取消</span>
        <span class="confirm" @click="saveSource">确认</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import headBar from "@/components/app/headBar";
import { getParam, getFormatDate } from '@/lib/utils'
import userMixin from '@/mixin/userMixin';
import choseCalendarVue from "./components/choseCalendar.vue";
import teamListVue from "./components/teamList.vue";
import memberListVue from "./components/memberList.vue";
import buyListVue from "./components/buyList.vue";
export default {
  components: {
    headBar, choseCalendarVue, teamListVue, memberListVue, buyListVue,
  },
  mixins: [userMixin],
  data() {
    return {
      stickyHeight: 0,
      title: '',
      totalInfo: {},
      menuIndex: 0, //0:个人数据，1:团队数据，2:项目数据
      isHistory: false,
      numObj: { member: '会员数(绑定)', clue: '线索', publicSea: '公海', inDoor: '进馆', invert: '转化' },
      numObj2: { member: '会员数', unbind: '未绑定', endTime: '到期解绑', manualUnbind: '手动解绑', leave: '离职解绑' },
      totalObj: {
        superDeerCard: '超鹿月卡', perCard: '包月私教卡', perPackage: '私教课包', card: '礼品卡', refund: '退款', bdsuperDeerCard: '超鹿月卡(用拓渠道)', bdperCard: '包月私教卡(用拓渠道)', bdperPackage: '私教课包(用拓渠道)', bdcard: "礼品卡(用拓渠道)", bdrefund: '退款(用拓渠道)'
      },
      topTipsShow: false,
      //时间选择
      nowTime: '',
      minDate: new Date(),
      maxDate: new Date(),
      timeText: '全部',
      // tab
      tabList: [{ name: '我的团队', key: 0 }, { name: '我的会员', key: 1 }, { name: '线索', key: 2 }, { name: '每日新增', key: 3 }, { name: '团队会员', key: 4 }, { name: '带货明细', key: 5 }, { name: '线索', key: 6 }, { name: '公海', key: 7 }, { name: '移出记录', key: 8 }],
      tabFilterList: [],
      hasTab: [[1, 2, 3, 5], [0, 3, 4, 5, 6, 7], [0, 3, 4, 5, 7], [7, 8]],
      active: '',
      oldTab: '',
      listHeight: 0,
      // 二维码
      qrShow: false,
      qrCode: '',
      teamList: [],
      // 跳转
      goType: '',
      goSTId: '',
      goUserId: '',
      goTag: 0,
      newTipsShow: false,
      tipsObj: {},
      // 二维码标签选择
      choseTagShow: false,
      tagNameList: [],
      tagSourceId: '',
      untagSourceId: '',
      tagName:''
    }
  },
  async mounted() {
    await this.$getAllInfo(['token'])
    this.menuIndex = getParam().menuIndex
    this.isHistory = getParam().isHistory == 'true'
    this.getData(this.menuIndex == 0 ? 1 : 0)
    this.getNewTips()
    setTimeout(() => {
      this.stickyHeight = this.$refs.header.$el.offsetHeight - 1
      this.listHeight = this.$refs.listRef.getBoundingClientRect().y
    }, 1000)
  },
  watch: {
    menuIndex() {
      this.listHeight = this.$refs.listRef.getBoundingClientRect().y
      this.tabFilterList = this.tabList.filter(i => this.hasTab[this.goSTId == -1 ? 3 : this.menuIndex].indexOf(i.key) > -1)
    }
  },
  methods: {
    goTeamAndUser({ type, id }) {
      this.goTag++
      this.goType = type
      type == 'team' ? (this.goSTId = id, this.menuIndex = 1) : (this.goUserId = id, this.menuIndex = 0)
      this.active = ''
      this.getData(type == 'team' ? (id == -1 ? 7 : 0) : 3)
    },
    backFun() {
      if (this.goTag > 0) {
        this.goTag--
        this.menuIndex++
        this.goType = this.goTag == 1 ? 'team' : this.goType
        this.goSTId = this.goSTId == -1 ? '' : this.goSTId
        this.active = ''
        this.getData(0)
      } else {
        this.$router.replace('/user-expansion/list?menuIndex=' + this.menuIndex + '&isHistory=' + this.isHistory + '&saleTeamId=' + getParam().saleTeamId)
      }
    },
    showTime() {
      this.$refs.choseTimeRef.showTime({ chose: this.timeText })
    },
    choseTimeFun(v) {
      this.timeText = v.timeText
      this.getData(this.active)
    },
    selectAllFun() {
      this.timeText = '全部'
      this.getData(this.active)
    },
    getData(act) {
      this.getTotal()
      this.tabChange(act)
      window.scrollTo(0, 0)
    },
    tabChange(key) {
      this.active = key
      this.$nextTick(() => {
        if (this.active == 0 || this.active == 3) {
          this.$refs.teamRef.getData(this.getD())
        } else if ([1, 2, 4, 6, 7, 8].indexOf(this.active) > -1) {
          this.$refs.memberRef.getData(this.getD())
        } else if (this.active == 5) {
          this.$refs.buyRef.getData(this.getD())
        }
      })
    },
    getD() {
      let d = {
        activityId: getParam().activityId,
        bindStartTime: this.timeText == '全部' ? '' : getFormatDate(this.timeText.split('-')[0].replace(/\./ig, '/')) + ' 00:00:00',
        bindEndTime: this.timeText == '全部' ? '' : getFormatDate(this.timeText.split('-')[1].replace(/\./ig, '/')) + ' 23:59:59',
      }
      if (this.goTag > 0) {
        this.goType == 'team' ? d.saleTeamId = this.goSTId : (d.userId = this.goUserId, d.saleTeamId = this.goSTId)
      } else {
        this.menuIndex == 0 ? d.userId = getParam().userId : this.menuIndex == 1 ? d.saleTeamId = getParam().saleTeamId : d.isProject = true
      }
      return d
    },
    getTotal() {
      let d = this.getD(true)
      this.$axios.post(this.baseURLApp + (this.goSTId == -1 ? '/userExpansionBing/query/getHeadDetailPublicSea' : "/userExpansionBing/query/getHeadDetail"), d)
        .then((res) => {
          this.totalInfo = res.data
          this.nowTime = getFormatDate(new Date(), 'yyyy.MM.dd hh:mm:ss')
          let t = res.data.activityTime.split('-')
          this.minDate = new Date(t[0].replace(/\./ig, '/'))
          this.maxDate = new Date(t[1].replace(/\./ig, '/'))
          this.title = this.menuIndex == 0 ? this.totalInfo.userName + ' · ' + this.totalInfo.saleTeamName : this.menuIndex == 1 ? this.totalInfo.saleTeamName : this.totalInfo.activityName
        })
    },
    choseTagName() {
      this.$axios.post(this.baseURLApp + '/userExpansionBing/other/listBySource',
        { activityId: getParam().activityId }).then((res) => {
          if (res.data.length > 0) {
            this.tagNameList = res.data
            this.tagSourceId = this.tagNameList[0].sourceId
            this.untagSourceId = this.tagSourceId
            this.tagName = this.tagNameList[0].name
            this.choseTagShow = true
          } else {
            this.$toast('暂无获客标签，请联系主管添加二维码后，重新获取二维码')
          }
        })
    },
    choseSource(i) {
      this.untagSourceId = i.sourceId
      this.tagName = i.name
    },
    cancel() {
      this.choseTagShow = false
      this.untagSourceId = this.tagSourceId
    },
    saveSource() {
      this.tagSourceId = this.untagSourceId
      this.choseTagShow = false
      this.getPosterUrl()
    },
    getPosterUrl() {
      this.$axios.post(this.baseURLApp + "/crm/teacherActivitySale/poster", {
        token: this.token,
        userId: getParam().userId,
        teacherId: this.totalInfo.teacherId,
        activityId: getParam().activityId,
      }).then((res) => {
        this.showQr(res.data.webUrl)
      })
    },
    showQr(url) {
      let d = {
        token: this.token,
        userId: getParam().userId,
        teacherId: this.totalInfo.teacherId,
        width: 240,
        height: 240,
        webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
        scene: JSON.stringify({
          source: `activity_${getParam().activityId}`,
          inviteTeacherId: this.totalInfo.teacherId,
          inviteUserId: getParam().userId,
          userId: 1,
          id: getParam().activityId,
          webUrl: `${window.location.origin}${url}`,
          userExpansionSourceId: this.tagNameList.length > 0 ? this.tagSourceId : '',
        }),
      }
      this.$axios.post(this.baseURLApp + "/qrCode/createOrdinaryCode/qrCode", d)
        .then((res) => {
          this.qrShow = true
          this.qrCode = res.data.qrCode
        })
    },
    teamListFun(v) {
      this.teamList = v
    },
    getNewTips() {
      this.$axios.post(this.baseURLApp + '/userExpansionBing/query/popUpReminder', this.getD())
        .then((res) => {
          this.tipsObj = res.data
          this.newTipsShow = Object.keys(res.data).filter(i => res.data[i] > 0).length > 0
        })
    }
  },
}

</script>

<style lang="less" scoped>
@import "./components/index.less";

.container {
  * {
    box-sizing: border-box;
  }
}

// 头部
.sticky {
  position: sticky;
  z-index: 100;

  .top {
    background-color: #3665BD;
    color: #fff;
    padding: 24px 28px;

    .time {
      font-size: 22px;
      padding: 12px 16px;
      border: 1px solid #ffffff7f;
      border-radius: 4px;
      display: flex;
      align-items: center;
      line-height: 1;

      i {
        margin-left: 10px;
      }
    }

    .qr-code {
      font-size: 24px;
      font-weight: 600;
      color: #242831;
      background: #fff;
      width: 190px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      line-height: 1;

      i {
        margin-right: 4px;
        font-size: 28px;
      }
    }

    .num {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      font-size: 24px;

      >div {
        &:first-child {
          width: 50px;
          white-space: nowrap;
        }

        >p:last-child {
          margin-top: 14px;

          >span:first-child {
            font-size: 48px;
            font-weight: 600;
            font-family: BebasNeueBold;
          }
        }
      }
    }

    .total {
      display: flex;
      flex-wrap: nowrap;
      margin-top: 20px;
      overflow-x: scroll;

      >div {
        min-width: 130px;
        flex-shrink: 0;
        margin-right: 10px;
        border: 1px solid #ffffff42;
        border-radius: 8px;
        padding: 12px 14px;

        >p:first-child {
          font-size: 22px;
          font-weight: 400;
          margin-bottom: 4px;
        }

        >p:nth-of-type(n+2) {
          font-size: 24px;
          font-weight: 400;
          margin-top: 4px;

          >span:last-child {
            font-size: 20px;
            margin-left: 4px;
          }
        }
      }
    }

    .tips {
      font-size: 22px;
      font-weight: 300;
      color: #EEEEEE;
      margin-top: 20px;
      line-height: 1.6;
    }
  }

  .tabs {
    height: 76px;
    line-height: 76px;
    font-size: 24px;
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 1px solid #EEEEEE;
    background-color: #fff;

    .tab {
      padding: 0px 20px;
      flex-shrink: 0;

      &.act {
        color: #3665BD;
        font-weight: 600;

        span {
          border-bottom: 4px solid #3665BD;
          padding-bottom: 23px;
        }
      }
    }
  }
}

// 弹窗
.qr-box {
  text-align: center;
  overflow-y: visible;
  width: 600px;
  // height: 776px;
  border-radius: 20px;
  padding-top: 80px;
  padding-bottom: 40px;

  .close {
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: -120px;
    right: 50%;
    transform: translate(50%, 0);
  }

  .qr {
    width: 560px;
    height: 560px;
  }
}

.tips-box,
.new-tip-pop {
  width: 600px;
  border-radius: 20px;
  padding: 64px 0px 40px;
  font-size: 26px;
  box-sizing: border-box;
  text-align: center;

  .n-title {
    font-size: 36px;
    padding-bottom: 40px;
    font-weight: 600;
  }

  p+p {
    padding-top: 10px;
  }

  .blue {
    color: #267DFF;
    margin-left: 10px;
    margin-right: 10px;
  }

  .btns {
    border-top: 1px solid #EAEAEA;
    margin-top: 40px;
    padding-top: 40px;
    font-size: 32px;
    color: #267DFF;
  }
}

.tips-box {
  padding: 30px;
}
.choseTag{
  max-height: 700px;
  overflow: scroll;
}
</style>
