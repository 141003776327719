<template>
  <div class="page-rooter-fff" :style="{ 'padding-top': packageTabs.length > 1 ? '56px' : '32px' }">
    <nav-bar headerTitle="课包购买" ref="head" :showBack="true" :showShare="true" @right-txt-click="showShare = true"
      :headerBack="newAppBack"></nav-bar>
    <app-page>
      <van-tabs class="tabs" :style="`top: ${headHeight}px`" v-if="packageTabs.length > 1" v-model="search.packageType"
        :title-active-color="tabColor" :color="tabColor" line-width="15" line-height="2" @change="onChangeTab">
        <van-tab :title="item.title" :name="item.packageType" v-for="item in packageTabs" :key="item.title"></van-tab>
      </van-tabs>

      <!-- <p style="width: 100px; height: 100px; margin: 50px; background: black; color: #fff;" @click="buy">点击购买</p> -->
      <!-- <img class="coach-banner" @click="jumpWeb" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/coach/coachBanner_package.png"> -->

      <div class="top-img">
        <img :src="tabImg">
      </div>
      <div class="package-title">
        购买课包
      </div>
      <div class="package-select">
        <div
          :style="{ 'background-color': (item.packageDes === search.packageDes) ? tabColor : '', 'color': (item.packageDes === search.packageDes) ? '#fff' : '#333' }"
          v-for="(item, index) in packageTypeList" :key="index" @click="switchType(item.packageDes, index)">
          {{ item.packageDes }}</div>
      </div>

      <div class="package-list">
        <template v-for="(item, index) in packageList">
          <div class="package-item"
            :style="{ 'border-color': (item.id === selectItem.id) ? tabColor : '', 'background-color': (item.id === selectItem.id) ? hexToTgba(tabColor, .1) : '', }"
            @click="switchItem(item, index)" :key="item.id">
            <div class="package-item-left">
              <div>
                <p>{{ item.packageName }}</p><span class="tips">有效期{{ item.effectiveDays }}天</span>
              </div>
              <div>
                <span>¥{{ Math.round(item.discountSinglePriceStr * 10) / 10 }}/节</span>
                <span class="original-tips">¥{{ Math.round(item.originalSinglePrice * 10) / 10 }}/节</span>
              </div>
            </div>
            <div class="package-item-right">
              <p class="f32 fw6">¥{{ item.discountPriceStr }}</p>
              <p class="f22 p2">¥{{ item.originalPriceStr }}</p>
            </div>
          </div>
        </template>
      </div>
      <div class="package-title">
        购买须知
      </div>
      <div class="bottom-tip">
        <p v-html="replaceReturn(packageRule)"></p>
      </div>
      <div class="ios-padding"></div>

      <div class="bottom-btn">
        <div class="bottom-btn-shadow">
          <img
            src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/package_presentation3.png">
        </div>
        <div class="bottom-btn-box">
          <div class="row-start-center">￥{{ (selectItem && selectItem.discountPriceStr) ? selectItem.discountPriceStr : 0 }}
            <div class="discount row-center-center f20 fw6">可省{{ selectItem.favourablePriceStr }}元</div>
          </div>
          <div :style="{ 'background-color': tabColor }" @click="subOrder">立即购买</div>
        </div>
      </div>
    </app-page>
    <!--  分享  -->
    <common-share :value="showShare" :shareParams="shareParams" :shareItem="['minifriend']" @close="showShare = false"
      @share-success="showShare = false">
    </common-share>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar/nav-bar'
import appPage from '@/common/components/appPage'
import commonShare from '@/components/commonShare'
import {
  appPay,
  appPaySuccess,
  closeWindow,
  appToast, newAppBack
} from '@/lib/appMethod'
import { appHeaderAdapt, getParam } from '@/lib/utils'


export default {
  data() {
    return {
      showShare: false,
      shareParams: {
        title: '上超鹿，买课包；上精选团课、私教课和小班课；',
        miniImage: 'https://img.chaolu.com.cn/MINI/minicard/5.png',
        path: '',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: "0.95",
        userName: "gh_7f5b57b6a76e",
      },
      payParams: { // 购买
        token: '',
        userId: '',
        venueId: 1,
        subject: '',
        originalPriceStr: '',
        totalAmount: '0.01', // 价格
        mouldId: 1004, // 商品的id
        type: "10",
        title: '',
        whetherPrivacy: 0,
        extendsJson: { appVersion: '2.40.11', sourceCityId: '' },
      },

      search: {
        token: '',
        userId: '',
        packageType: '1',
        packageDes: '',
        cityId: '',
      },

      packageRule: '',// 底部购买须知
      selectItem: {},// 底部购买须知

      appCommParams: [],
      packageTypeList: [],
      packageList: [],
      // appTypeStr: '',
      packageTabs: [], // tabs

      headHeight: 0,
      seller: '', // 绑定扫码销售来源
    };
  },
  computed: {
    tabColor() {
      let activeTab = this.packageTabs.filter((item) => item.packageType == this.search.packageType)[0]
      return activeTab ? activeTab.pageColor : ''
      // return '#ffde00'
    },
    tabImg() {
      let activeTab = this.packageTabs.filter((item) => item.packageType == this.search.packageType)[0]
      return activeTab ? activeTab.topMainPic : ''
    },
  },
  mixins: [userMixin],
  components: {
    navBar,
    appPage,
    commonShare
  },
  async created() {
    await this.$getAllInfo(['userId', 'cityId'])

    this.payParams.token = this.search.token = this.token
    this.payParams.userId = this.search.userId = this.userId
    this.payParams.extendsJson.sourceCityId = this.cityId
    this.search.cityId = this.cityId

    let sourceChannel = getParam().sourceChannel
    let packageDes = getParam().packageDes
    this.seller = getParam().seller
    if (this.seller) {
      this.payParams.extendsJson.seller = this.seller
      this.getInviteUserInfo()
    }
    if (sourceChannel) {
      this.payParams.extendsJson.sourceChannel = sourceChannel
    }
    this.search.packageDes = packageDes ? decodeURIComponent(packageDes) : ''
    this.queryTabType()
    appPaySuccess().then((res) => {
      // 如果是小班课 关闭app webview
      if (getParam().from === 'miniClass') {
        closeWindow()
      }
      appToast('支付成功')
    }).catch((res) => {
      res && appToast(res)
    })

    const uu = encodeURIComponent(`${window.location.origin}/#/course-buy`)
    this.shareParams.path = `/pages/webView/index?webUrl=${uu}`

    wx.miniProgram.postMessage({
      data: {
        type: 'share',
        shareImage: this.shareParams.miniImage,
        shareTitle: this.shareParams.title,
        shareUrl: this.shareParams.path,
      },
    })
  },
  mounted() {

    if (this.appTypeStr === 'ios' || this.appTypeStr === 'and') {
      this.headHeight = appHeaderAdapt()
    }

  },
  methods: {
    newAppBack,
    // 接受好友邀请
    getInviteUserInfo() {
      this.$axios.post(this.baseURLApp + '/userDistribute/bindUser', {
        token: this.token,
        userId: this.userId,
        activityNo: 'CustomerServiceBringsGoods',
        saleTeacherId: this.seller,
        hasWx: true,
      }).then((res) => {
        console.log(res)
      })
    },
    queryTabType() {
      this.$axios.post(this.baseURLApp + '/app/queryBuyCoursePackageTabs/v1', this.search).then((res) => {
        this.packageTabs = res.data.list
        let packageTypeFromUrl = getParam().packageType
        console.log('djfkdsfjkdjfkds', window.location.href);
        this.search.packageType = packageTypeFromUrl ? packageTypeFromUrl : this.packageTabs[0].packageType

        this.queryCoursePackageType()
      })
    },
    queryCoursePackageType() {
      this.$axios.post(this.baseURLApp + '/app/queryCoursePackageType/v1', this.search).then((res) => {
        if (res.data && res.data.length > 0) {
          this.search.packageDes = this.search.packageDes ? this.search.packageDes : res.data[0].packageDes
        }
        this.packageTypeList = res.data
        this.getPackageList()
      })
    },
    switchType(name, index) {
      this.search.packageDes = name
      this.getPackageList()
    },
    onChangeTab(name, title) {
      this.search.packageDes = ''
      this.queryCoursePackageType()

      // 修改分享参数
      const url = encodeURIComponent(`${window.location.origin}/#/course-buy`)
      this.shareParams.path = `/pages/webView/index?packageType=${this.search.packageType}&webUrl=${url}`
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })
    },
    getPackageList() {
      this.$axios.post(this.baseURLApp + '/app/getPackageList/v1', this.search).then((res) => {
        if (res.data.coursePackage) {
          this.packageList = res.data.coursePackage
          this.selectItem = this.packageList[0]
          let id = getParam().id
          if (id) {
            let v = this.packageList.filter((item) => { return item.id === id })[0]
            if (v) {
              this.selectItem = v
            }
          }
          this.packageRule = this.selectItem?.purchaseNotes || ''
        }
      })
    },
    switchItem(item, index) {
      this.selectItem = item
    },
    //支付
    subOrder() {
      // 自定义加载图标
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 1000
      });
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      this.payParams.mouldId = this.selectItem.id
      this.payParams.totalAmount = this.selectItem.discountPriceStr
      this.payParams.subject = this.selectItem.packageName
      this.payParams.originalPriceStr = this.selectItem.originalPriceStr
      this.payParams.title = this.selectItem.packageDes
      // 带 url 代表是小班课
      const url = getParam().url
      if (url) {
        // console.log(url);
        // console.log(decodeURIComponent(url))
        appPay(this.payParams, decodeURIComponent(url))
      } else {
        appPay(this.payParams, `/pages/webView/index?webUrl=${window.location.origin}/#/course-buy&userId=1&token=1&location=1`)
      }


    },
    //  规则页面
    jumpWeb() {
      wx.navigateTo({
        url: '/pages/webView/index?webUrl=appWeb/privateEducationrDivisionTime/learnPackCancelRules.html'
      })
    },
    hexToTgba(hex, opacity = 1) {
      if (hex != '' && hex.startsWith('#') && (hex.length == 4 || hex.length == 7)) {
        if (hex.length == 4) {
          let str = '#'
          for (let i = 1; i < hex.length; i++) {
            let item = hex[i]
            str += item.repeat(2)
          }
          hex = str
        }
        let reg = /[0-9a-fA-F]{2}/g;
        let match = hex.match(reg)
        match = match.map(item => {
          return parseInt(`0x${item}`)
        })
        return `rgba(${match.toString()},${opacity})`
      }
      return 'What You Want ?'
    },
    replaceReturn(str) {
      var regRN1 = /(\r\n)/g
      var regRN2 = /(\n)/g
      if (typeof (str) != 'string') {
        return str
      }
      str = str.replace(regRN1, '<br><br>');
      str = str.replace(regRN2, '<br>');

      return str;
    }

  },
};
</script>

<style lang="less" scoped>
.page-rooter-fff {

  // &.tab {
  //   padding: 120px 0 0;
  // }
  .app-page-main {
    overflow: inherit !important;
    height: auto !important;
  }

  .tabs {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .top-img {
    width: 750px;
    padding: 40px 30px;
    box-sizing: border-box;
    background-color: white;

    img {
      width: 100%;
      display: block;
    }
  }

  .van-hairline--top-bottom:after {
    border-width: 0 !important;
  }

  .package-title {
    font-size: 32px;
    font-weight: bold;
    color: #1f2025;
    padding: 20px 30px;
  }

  .package-select {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    width: 750px;
    box-sizing: border-box;
    overflow: hidden;
    flex-wrap: wrap;
  }

  .package-select div {
    padding: 0 15px;
    height: 60px;
    line-height: 60px;
    background-color: #F6F6F6;
    box-sizing: border-box;
    font-size: 24px;
    color: #333333;
    font-weight: 500;
    margin-right: 30px;
    border-radius: 30px;
    margin-bottom: 30px;
    width: 210px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
  }

  .package-select div:nth-child(3n) {
    margin-right: 0;
  }

  .package-list {
    padding: 0 30px 20px;
    width: 100vw;
    box-sizing: border-box;
  }

  .package-item {
    padding: 40px 40px 40px 30px;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .package-item+.package-item {
    margin-top: 30px;
  }

  .package-item-left>div:first-child {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 32px;

    .tips {
      font-size: 24px;
      color: #6C727A;
      font-weight: normal;
      margin-top: 16px;
      display: inline-block;
    }
  }

  .package-item-left>div:first-child>text {
    color: #666666;
    font-size: 24px;
    font-weight: normal;
    margin-left: 20px;
  }

  .package-item-left>div:last-child {
    color: #F03C18;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    display: flex;
    align-items: flex-end;

    .original-tips {
      margin-left: 12px;
      line-height: 22px;
      font-size: 22px;
      color: #6C727A;
      font-weight: normal;
      text-decoration: line-through;
    }
  }

  .package-item-right {
    color: #F03C18;
    flex-shrink: 0;
    text-align: right;
  }

  .package-item-right .p2 {
    color: #6C727A;
    text-decoration: line-through;
  }

  .bottom-tip {
    color: #999999;
    padding: 0 30px 162px;
    font-size: 24px;
  }

  .bottom-tip text {
    line-height: 50px;
  }

  .ios-padding {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .bottom-btn {
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    box-sizing: border-box;
  }

  .bottom-btn-shadow image {
    width: 100%;
    height: 12px;
    display: block;
  }

  .bottom-btn-box {
    background-color: white;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: env(safe-area-inset-bottom);
  }

  .bottom-btn-box>div:first-child {
    color: #F03C18;
    font-weight: bold;
    font-size: 42px;
  }

  .bottom-btn-box .discount {
    padding: 0 10px;
    height: 36px;
    background: #F03C18;
    border-radius: 4px 4px 4px 4px;
    margin: 0 0 0 16px;
    color: #fff;
  }

  .bottom-btn-box>div:last-child {
    color: white;
    width: 240px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background-color: #FDB928;
    border-radius: 40px
  }

  .coach-banner {
    width: 686px;
    display: block;
    margin: 0 auto;
    height: 220px;
    border-radius: 8px;
  }
}</style>
