import { render, staticRenderFns } from "./course-buy-bymall.vue?vue&type=template&id=60826f37&scoped=true&"
import script from "./course-buy-bymall.vue?vue&type=script&lang=js&"
export * from "./course-buy-bymall.vue?vue&type=script&lang=js&"
import style0 from "./course-buy-bymall.vue?vue&type=style&index=0&id=60826f37&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60826f37",
  null
  
)

export default component.exports